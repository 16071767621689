//import logo from './logo.svg';
import './App.css';
import Amplify, { API } from 'aws-amplify'
import React, { Component} from 'react';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import logo from './images/Jan25QR.png';
import janpdf from './qrcodejan25.pdf';
import febpdf from './qrcodefeb25.pdf';
import marpdf from './qrcodemar25.pdf';

function App() {

    return (
      <div className = "App">
        
      <Authenticator>
      {({ signOut, user }) => (
        <div className="App">
          <p>
            Hello {user.username}, Welcome to BCSW Amarra
          </p>
          <button onClick={signOut}>Sign out</button>
          <ul></ul>
        </div>
      )}
      </Authenticator>

          <img src={logo} alt="Logo" width="250" height="250"/>

          <p>
           Jan 2025 Gate Code: <b>76787</b>
          <br></br>
          Pass and Code expire 2/10/2025
          </p>
            <a
            className="App-link"
            href="https://pass.myq.com/H1qqqUSGj0i6Xv_RZGUYTgCI6iOyseDEifvCZxnmEzDw"
            target = "blank"
            rel = "noopener noreferrer"
            >
              Follow this link to open gate by tapping your screen
            </a>
            <br></br>
            <br></br>

            <a href = {janpdf} target = "_blank">Print January 2025 QR Code Sheet</a>
                <br></br>
            <a href = {febpdf} target = "_blank">Print February 2025 QR Code Sheet</a>
                <br></br>
            <a href = {marpdf} target = "_blank">Print March 2025 QR Code Sheet</a>
                <br></br>

      </div>
      
    );
    
}
export default withAuthenticator(App);
